import { useParams } from "react-router-dom";
import React, { useEffect, useState, useMemo } from 'react';
import '../../style/discover.css';
import { Button, Input, Switch, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { CloudStorageImageUploader } from "../../components/upload";
import { useLocation } from "react-router-dom";
import { updateBanner } from "../../api";
import { MainLayoutUrl } from "../../router";
import { useNavigate } from "react-router-dom";

export const BannerOptions = [
    { key: '0', value: 0, label: 'Home Banner' },
    { key: '1', value: 1, label: 'Account Banner' },
    { key: '2', value: 2, label: 'Wallet Banner' },
    { key: '3', value: 3, label: 'Market Banner' },
    { key: '4', value: 4, label: 'Discover Banner' },
    { key: '5', value: 5, label: 'Sign Up Banner' },
    { key: '6', value: 6, label: 'Introduction Page' },
    { key: '7', value: 7, label: 'Sign Up Popup' },
    { key: '8', value: 8, label: 'Event Popup' },
    { key: '9', value: 9, label: 'Push Popup' },
    { key: '10', value: 10, label: 'H5 Top Banner' },
    { key: '11', value: 11, label: 'H5 Top Banner Signed-In' },
    { key: '12', value: 12, label: 'Private Market Banner' },
];

const BannerDetailScreen = (props) => {
    const navigateTo = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const [data, setData] = useState(location.state && location.state.detail ? JSON.parse(location.state.detail) : {});
    const [updatedData, setUpdatedData] = useState();

    useEffect(() => {
    }, [])

    const buttonNode = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <div className="banner-upload-btn">
                <PlusOutlined style={{ fontSize: 20, color: '#afafaf' }} />
                <div style={{ marginTop: 4, fontSize: 14, color: '#afafaf' }}>Upload Images</div>
            </div>
        </button>
    )

    const onUpdate = (fileList) => {
        if (!fileList || fileList.length <= 0) return;
        let updated = { ...data };
        let imgList = fileList.map((item, index) => {
            let imgurl = item.remoteUrl ? item.remoteUrl : item.url;
            if (updated.list && updated.list[index]) {
                return {
                    id: updated.list[index].id,
                    bannerId: updated.id,
                    image: imgurl,
                    source: item.action,
                }
            }
            return {
                bannerId: updated.id,
                image: imgurl,
                source: item.action,
            }
        });
        updated.list = imgList;
        setUpdatedData({ ...updated });
    }

    const uploadProps = {
        buttonNode: buttonNode,
        name: 'banner_uploader',
        showUploadList: true,
        defaultFileList: useMemo(() => {
            return (data.list ?? []).map((img) => {
                return {
                    name: (decodeURIComponent(img.image).split('/').pop() ?? '').split('?')[0],
                    url: String(img.image).includes('https') ? img.image : `https://${img.image}`,
                    action: img.source,
                    percent: 100,
                    id: img.id,
                    bannerId: img.bannerId,
                };
            });
        }, [data.list]),
        listType: 'picture',
        isMultiple: true,
        bucketPath: `/banner/${data.id}`,
        onUpdate: onUpdate,
    }

    const onInput = (key, value) => {
        data[key] = value;
        setData({ ...data });
    }

    const toSubmit = () => {
        let params = { ...data, ...updatedData };
        updateBanner(params).then(res => {
            navigateTo(MainLayoutUrl.banner.index.path);
        });
    }

    const toCancel = () => {
        navigateTo(MainLayoutUrl.banner.index.path);
    }

    return <div style={{ padding: '20px 40px' }}>
        <div className="title">{id ? (id === '0' ? 'Create New Banner' : 'Edit Banner') : ''}</div>
        <div style={{ height: 30 }} />
        <div className="input-item">
            <div className="lab">Title</div>
            <div className="input">
                <Input placeholder="please input banner name" value={data.title} onChange={(evt) => onInput('title', evt.target.value)} />
            </div>
        </div>
        <div className="input-item">
            <div className="lab">Display Area</div>
            <div className="input">
                <Select value={data.type} style={{ width: '100%' }} placeholder="display area" onChange={(value) => onInput('type', value)} options={BannerOptions}></Select>
            </div>
        </div>
        <div className="input-item">
            <div className="lab">Images</div>
            <div className="input" style={{ border: '1px dashed #f1f1f1' }}>
                <CloudStorageImageUploader {...uploadProps} />
            </div>
        </div>
        <div className="input-item">
            <div className="lab">Desc</div>
            <div className="input">
                <Input.TextArea placeholder="please input banner description" showCount maxLength={400} value={data.description} onChange={(evt) => onInput('description', evt.target.value)} />
            </div>
        </div>
        <div className="input-item">
            <div className="lab">Status</div>
            <div className="input">
                <Switch checkedChildren='Active' unCheckedChildren='Inactive' checked={String(data.status) === '0'} onChange={(value) => onInput('status', value ? 0 : 1)} />
            </div>
        </div>
        <div className="btn-area">
            <Button type="primary" style={{ width: 200, height: 44 }} onClick={toSubmit}>Submit</Button>
            <div style={{ width: 24 }} />
            <Button block style={{ width: 200, height: 44 }} onClick={toCancel}>Cancel</Button>
        </div>
    </div>
}

export default BannerDetailScreen;