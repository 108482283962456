import { useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { approvePostOrComment, fetchCommentApproveList } from "../../api/community";
import { Button, Pagination, Table, Flex, Select, Space, Input, Tag, message, Popover, Image } from "antd";
import { EyeFilled } from "@ant-design/icons";
import { useDebouncedCallback } from "use-debounce";
import dayjs from "dayjs";
import { RichText } from "@chatui/core";
import '../../style/community.css';
import UserInfoView from "../../components/community/user_info_view";
import PostReview from "../../components/community/post_review";
import CommenApproveRecordsModal from "../../components/community/comment_approve_records_modal";

const PendingCommentsScreen = () => {
    const location = useLocation();
    const [currPost, setCurrPost] = useState();
    const tabCols = [
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf', whiteSpace: 'nowrap' }}>{dayjs(text).format('MM-DD HH:mm')}</label>
            },
        },
        {
            title: 'Comment Id',
            dataIndex: 'commentId',
            key: 'commentId',
            render: (_, record) => {
                const { id } = record.comment;
                return <div style={{ fontSize: 12 }}>{id}</div>
            },
        },
        {
            title: 'Comment',
            dataIndex: 'content',
            key: 'content',
            render: (_, record) => {
                const { comment } = record;
                let resources = comment.resources;
                let content = comment.content;
                let title = comment.title;
                if(record.content) {
                    content = record.content;
                }
                if(record.title) {
                    title = record.title;
                }
                if(record.resources && record.resources.length > 0) {
                    resources = record.resources;
                }
                return (
                    <div>
                        <UserInfoView user={comment.user} showWallet={false} />
                        <div style={{ background: '#f1f1f1', padding: '8px 12px', borderRadius: 8, maxWidth: 240, marginTop: 8, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div className="community-content">
                                {Array.from(resources ?? []).length > 0 ? '「IMAGE」' : ''}
                                <RichText content={title ? title : content} />
                            </div>
                            <Popover
                                placement="right"
                                title=''
                                content={
                                    <div className="post-content-review" style={{ maxWidth: 300, maxHeight: 400, overflowY: 'auto' }}>
                                        <RichText content={content} />
                                        {
                                            Array.from(resources ?? []).map(img => {
                                                return <img key={img.id} alt="addx go comment image" src={img.url} style={{ width: '100%', height: 'auto', marginTop: 8 }} />
                                            })
                                        }
                                    </div>
                                }
                                trigger={'click'}>
                                <EyeFilled style={{ color: 'grey', cursor: 'pointer' }} />
                            </Popover>
                        </div>
                    </div>
                )
            },
        },
        {
            title: 'Related Post',
            dataIndex: 'post',
            key: 'post',
            render: (_, record) => {
                const { comment } = record
                if (!comment.post || !comment.post?.user) return '-';
                return (
                    <div>
                        <UserInfoView user={comment.post?.user} showWallet={false} />
                        <div style={{ background: '#fafafa', padding: '8px 12px', borderRadius: 8, maxWidth: 240, marginTop: 8, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div className="community-content">
                                <RichText content={comment.post.title ? comment.post.title : comment.post.content} />
                            </div>
                            <EyeFilled style={{ color: 'grey', cursor: 'pointer' }} onClick={() => reviewHandle(comment.post)} />
                        </div>
                    </div>
                )
            },
        },
        {
            title: 'NLP Result',
            dataIndex: 'nlpResult',
            key: 'nlpResult',
            render: (text, _) => {
                if(!text) return '';
                const nlps = JSON.parse(text);
                return (
                    <Space size={1}>
                        {nlps.map((item, index) => {
                            return <Tag key={index}>{item.name}: <b>{item.confidence}</b></Tag>
                        })}
                    </Space>
                )
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, _) => {
                if (text === 'ApprovePending') {
                    return <Tag color="blue" style={{ fontSize: 12 }}>{text}</Tag>;
                } else if (text === 'ApproveApproved') {
                    return <Tag color="green" style={{ fontSize: 12 }}>{text}</Tag>;
                } else if (text === 'ApproveReject') {
                    return <Tag color="red" style={{ fontSize: 12 }}>{text}</Tag>;
                }
            },
        },
        {
            title: 'Reviewer',
            dataIndex: 'user',
            key: 'user',
            render: (text, _) => {
                if(!text || !text.userId) return '';
                return <UserInfoView user={text} showWallet={false} />
            },
        },
        {
            title: 'Review Time',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (text, _) => {
                if(!text) return '';
                return <label style={{ fontSize: 12, color: '#afafaf', whiteSpace: 'nowrap' }}>{dayjs(text).format('MM-DD HH:mm')}</label>
            },
        },
        // {
        //     title: 'Review Records',
        //     dataIndex: 'record',
        //     key: 'record',
        //     render: (_, record) => {
        //         return (
        //             <Button onClick={() => toApprovalModal(record)} type="text" size="small" style={{fontSize: 11, color: 'blue', padding: 0}}>Check Records</Button>
        //         )
        //     },
        // },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            render: (text, record) => {
                if (record.isLatest && record.status === 'ApprovePending') {
                    return (
                        <Button.Group size='small'>
                            <Button type='link' onClick={() => { commentHandle(record, 1) }}>Approve</Button>
                            <Button danger type='link' onClick={() => { commentHandle(record, 2) }}>Reject</Button>
                        </Button.Group>
                    )
                }
                return ''
            },
        }
    ];

    const [searchParams, setSearchParams] = useState({
        userId: '',
        status: undefined,
        level: undefined
    });
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState();
    const [approvalModal, setApprovalModal] = useState(false);
    const [commentId, setCommentId] = useState();

    useEffect(() => {
        fetchData(pageNum, pageSize, searchParams);
    }, [location])

    const fetchData = (pNum, pSize, search) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            ...search,
        };
        fetchCommentApproveList(params).then(res => {
            if (res.page) {
                setTotal(res.page.total);
            }
            if (res.list) {
                let data = [...res.list];
                data.forEach(item => {
                    delete item['children'];
                });
                setTabData(data);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size, searchParams);
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            newParams[tag] = value;
            setSearchParams(newParams);
            setPageNum(1);
            fetchData(1, pageSize, newParams);
        },
        300
    )

    const reviewHandle = (post) => {
        setCurrPost(post);
        setOpen(true);
    }

    // const toApprovalModal = (record) => {
    //     setCommentId(record.commentId);
    //     setApprovalModal(true);
    // }

    const commentHandle = (record, status) => {
        setLoading(true);
        let params = { list: [{ id: record.id, status: status }] };
        approvePostOrComment(params).then(res => {
            if (res) {
                fetchData(pageNum, pageSize, searchParams);
                setSelectedRows([])
            }
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        });
    }

    const approvalBatch = (status) => {
        setLoading(true);
        let params = {
            list: selectedRows.map(item => {
                return { id: item.id, status: status }
            })
        };
        approvePostOrComment(params).then(res => {
            if (res) {
                fetchData(pageNum, pageSize, searchParams);
                setSelectedRows([])
            }
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify="space-between">
                    <Space>
                        {
                            (selectedRows && selectedRows.length > 0) && (
                                <>
                                    <Button onClick={() => approvalBatch(1)} type="primary">Batch Approve</Button>
                                    <Button onClick={() => approvalBatch(2)} danger type="primary">Batch Reject</Button>
                                </>
                            )
                        }
                        <div />
                    </Space>
                    <Space wrap style={{ justifyContent: 'flex-end' }}>
                        <Select
                            style={{ width: 160 }}
                            options={[
                                { key: 1, label: 'Pending', value: '0' },
                                { key: 2, label: 'Approved', value: '1' },
                                { key: 3, label: 'Rejected', value: '2' },
                            ]}
                            placeholder='Approve status'
                            onChange={value => onSearchChange('status', value)}
                            allowClear
                        />
                        <Select
                            style={{ width: 160 }}
                            options={[
                                { key: 1, label: 'Comment', value: 'comment' },
                                { key: 2, label: 'Reply', value: 'reply' },
                            ]}
                            placeholder='Comment type'
                            onChange={value => onSearchChange('commentCategory', value)}
                            allowClear
                        />
                        <Input
                            placeholder="username"
                            allowClear
                            onChange={evt => onSearchChange('userId', evt.target.value)}
                        />
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table
                    loading={loading}
                    rowKey={(record) => record.id}
                    rowClassName={'table-row'}
                    size='small'
                    dataSource={tabData}
                    columns={tabCols}
                    pagination={false}
                    rowSelection={{
                        selectedRowKeys: (selectedRows ?? []).map(item => item.id),
                        getCheckboxProps: (record) => ({
                            disabled: (record.status !== 'ApprovePending' || !record.isLatest),
                        }),
                        onChange: (keys, rows) => {
                            setSelectedRows(rows);
                        }
                    }} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>

            <PostReview
                from="comment_approval"
                open={open}
                post={currPost}
                onClose={() => setOpen(false)}
                onUpdate={(() => {
                    fetchData(pageNum, pageSize, searchParams);
                })}
                canEdit={false}
            />
            {/* <CommenApproveRecordsModal
                modalOpen={approvalModal}
                commentId={commentId}
                onClose={() => setApprovalModal(false)} /> */}
        </div>
    )
}

export default PendingCommentsScreen;