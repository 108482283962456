import request from "../utils/request";

export function fetchAssetClass(params) {
    return request.get('/private/market/asset', params);
}

export function createAssetClass(params) {
    return request.post('/private/market/asset/create', params);
}

export function updateAssetClass(params) {
    return request.patch('/private/market/asset/edit', params);
}

export function deleteAssetClass(id) {
    return request.dataDel(`/private/market/asset?id=${id}`);
}

export function fetchPMProducts(params) {
    return request.get('/private/market/product', params);
}

export function createPMProduct(params) {
    return request.post('/private/market/product/create', params);
}

export function updatePMProduct(params) {
    return request.patch('/private/market/product/edit', params);
}

export function deletePMProduct(id) {
    return request.dataDel(`/private/market/product?id=${id}`)
}

export function fetchPMRecommend(params) {
    return request.get('/private/market/recommended', params);
}

export function createPMRecommend(params) {
    return request.post('/private/market/recommended/create', params);
}

export function updatePMRecommend(params) {
    return request.patch('/private/market/recommended/edit', params);
}

export function deletePMRecommend(id) {
    return request.dataDel(`/private/market/recommended?id=${id}`);
}

export function fetchPMTopPicks(params) {
    return request.get('/private/market/picks', params);
}

export function createPMTopPicks(params) {
    return request.post('/private/market/picks/create', params);
}

export function updatePMTopPicks(params) {
    return request.patch('/private/market/picks/edit', params);
}

export function deletePMTopPicks(id) {
    return request.dataDel(`/private/market/picks?id=${id}`);
}

export function fetchPMInsights(params) {
    return request.get('/private/market/insights', params);
}

export function createPMInsights(params) {
    return request.post('/private/market/insights/create', params);
}

export function updatePMInsights(params) {
    return request.patch('/private/market/insights/edit', params);
}

export function deletePMInsights(id) {
    return request.dataDel(`/private/market/insights?id=${id}`);
}
