import { useEffect, useState } from 'react'
import { Button, Table, Pagination, Flex, Input, Space, Select, DatePicker } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import { conversionUtcDate } from '../../utils/comm';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const PMProductsScreen = () => {
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [currProduct, setCurrProduct] = useState();
    const [searchParams, setSearchParams] = useState({
        name: '',
        status: '',
        start: '',
        end: '',
    });

    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
        },
        {
            title: 'Product Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, _) => {
                return <b>{text}</b>
            }
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                if (!text) return '-';
                return <span style={{ fontSize: 12, color: '#afafaf' }}>{conversionUtcDate(text, null, true)}</span>
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { editHandle(record) }}>Edit</Button>
                    </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        getProducts(pageNum, pageSize, searchParams);
    }, [])

    const getProducts = useDebouncedCallback(
        (pNum, pSize, search) => {
            let params = {
                'page.num': pNum,
                'page.size': pSize,
                ...search
            }
            // setLoading(true);
            // fetchPointsProduct(params).then(res => {
            //     if (res.list) {
            //         setTabData(res.list);
            //     }
            //     if (res.page) {
            //         setTotal(res.page.total);
            //     }
            // }).finally(() => setLoading(false))
        },
        300
    )

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getProducts(page, size, searchParams);
    }

    const editHandle = (record) => {
        setCurrProduct(record);
        setOpen(true);
    }

    const createNewProduct = () => {
        setCurrProduct(null);
        setOpen(true);
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            if (tag === 'dates') {
                if (value.length == 2) {
                    newParams.start = `${value[0]}T00:00:00Z`;
                    newParams.end = `${value[1]}T23:59:59Z`;
                } else {
                    newParams.start = '';
                    newParams.end = '';
                }
            } else {
                newParams[tag] = value;
            }
            setSearchParams(newParams);
            setPageNum(1);
            getProducts(1, pageSize, newParams);
        },
        300
    )

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify='space-between'>
                    <Button type='primary' icon={<PlusOutlined />} onClick={createNewProduct}>Create New Product</Button>
                    <Space>
                        <Input
                            placeholder="keywords search"
                            allowClear
                            onChange={evt => onSearchChange('name', evt.target.value)}
                        />
                        <Select
                            style={{ width: 160 }}
                            options={[
                                { key: 1, label: 'Waiting', value: 0 },
                                { key: 2, label: 'On', value: 1 },
                                { key: 3, label: 'Off', value: 2 },
                            ]}
                            placeholder='status'
                            onChange={value => onSearchChange('status', value)}
                            allowClear
                        />
                        <DatePicker.RangePicker onChange={values => onSearchChange('dates', (values ?? []).map(date => dayjs(date).format('YYYY-MM-DD')))} />
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
        </div>
    )
}

export default PMProductsScreen;