import { useEffect, useState } from 'react';
import { fetchAssetClass, createAssetClass, updateAssetClass, deleteAssetClass } from "../../api/privatemarket";
import { Table, message, Typography, Flex, Button, Popconfirm, Pagination, Tag } from 'antd';


const PMAssetClassScreen = () => {
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);

    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => (index + 1),
        },
        {
            title: 'Asset Class Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                let val = record.name;
                return <Typography.Text editable={
                    {
                        onChange: (value) => {
                            val = value;
                        },
                        onEnd: () => {
                            editHandle({ ...record, name: val });
                        }
                    }
                }>{text}</Typography.Text>
            },
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text, record) => {
                let val = record.description;
                return <Typography.Text style={{ fontSize: 12, color: '#afafaf' }} editable={
                    {
                        onChange: (value) => {
                            val = value;
                        },
                        onEnd: () => {
                            editHandle({ ...record, description: val });
                        }
                    }
                }>{text}</Typography.Text>
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (String(text) === '0') return <Tag color='success'>Active</Tag>;
                return <Tag color='warning'>Inactive</Tag>
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        {
                            parseInt(record.id) > 0 && <Button type='link' onClick={() => { activeHandle(record) }}>{String(record.status) === '0' ? 'Inactive' : 'Active'}</Button>
                        }
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this asset class?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];

    const [tabData, setTabData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData(pageNum, pageSize);
    }, []);

    const fetchData = (pNum, pSize) => {
        setLoading(true);
        fetchAssetClass({ 'page.num': pNum, 'page.size': pSize }).then(res => {
            if (res.list) {
                setTabData(res.list);
            }
            if (res.page) {
                setTotal(res.page.total);
            }
        }).finally(() => setLoading(false))
    }

    const deleteHandle = (item) => {
        if (parseInt(item.id) <= 0) {
            setTabData([...tabData].filter(old => old !== item));
            return;
        }
        setLoading(true);
        deleteAssetClass(item.id).then(res => {
            if (res) {
                message.success('deleted!');
                setLoading(false);
                fetchData();
            }
        }).catch((err) => {
            message.error(String(err));
            setLoading(false);
        })
    }

    const editHandle = (params) => {
        setLoading(true);
        if (parseInt(params.id) > 0) {
            updateAssetClass(params).then(res => {
                if (res) {
                    message.success('updated!');
                    fetchData();
                }
            }).catch(err => {
                fetchData();
            }).finally(() => setLoading(false));
        } else {
            let newParams = { ...params };
            delete newParams['id'];
            createAssetClass(newParams).then(res => {
                if (res) {
                    message.success('created!');
                    fetchData();
                }
            }).catch(err => {
                fetchData();
            }).finally(() => setLoading(false));
        }

    }

    const activeHandle = (item) => {
        let params = { ...item, status: String(item.status) === '0' ? 1 : 0 };
        setLoading(true);
        updateAssetClass(params).then(res => {
            if(res) {
                fetchData(pageNum, pageSize);
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const addNewAssetClass = () => {
        if (tabData.findIndex(item => item.id === '-1') >= 0) return;
        setTabData([
            {
                id: '-1',
                name: '',
                description: '',
            }, ...tabData
        ]);
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size);
    }

    return (
        <div>
            <Flex justify='flex-end' style={{ paddingBottom: 10, marginRight: 10, marginTop: 10 }}>
                <Button type='primary' onClick={addNewAssetClass}>Create New AssetClass</Button>
            </Flex>
            <div className='cnt-item'>
                <Table
                    scroll={{ x: true }}
                    loading={loading}
                    rowKey={(record) => record.id}
                    rowClassName={'table-row'}
                    size='small'
                    dataSource={tabData}
                    columns={tabCols}
                    pagination={false} />
            </div>

            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
        </div>
    )
}
export default PMAssetClassScreen;