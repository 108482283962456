import { Descriptions, Input, Button, Tree, message, Checkbox } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { createAdminAction, createRole, fetchAdminActions, updateRole } from "../../api/admin";
import { MainLayoutUrl, menuItems } from "../../router";

const RoleDetailScreen = () => {
    const navigateTo = useNavigate();
    const { id } = useParams();
    const location = useLocation();

    const defaultData = location.state && location.state.detail ? JSON.parse(location.state.detail) : {};
    const [data, setData] = useState(defaultData);
    const [loading, setLoading] = useState(false);
    const [allActions, setAllActions] = useState([]);
    const [permission, setPermission] = useState([]);
    const [actionPermission, setActionPermission] = useState([]);
    const allMenus = [...menuItems];
    const actionOptions = [
        { value: 'default', label: 'Default (For default role)' },
        { value: 'auto_follow', label: 'Auto Follow (For admin role)' },
        { value: 'select_author', label: 'Select Author' },
        { value: 'can_be_selected', label: 'Can Be Selected' },
    ];

    useEffect(() => {
        initData();
    }, [])

    const initData = async () => {
        await getAllAction();
        if (data.id) {
            let actions = defaultData.actions.map(item => item.word);
            setPermission(actions.filter(item => !actionOptions.map(at => at.value).includes(item)));
            setActionPermission(actions.filter(item => actionOptions.map(at => at.value).includes(item)));
        }
    }

    const getAllAction = async () => {
        let data = await fetchAdminActions({ 'page.disable': true });
        if (data.list) {
            let actions = data.list.map(item => {
                return {
                    code: item.code,
                    name: item.menu,
                }
            });
            setAllActions(actions)
            return actions;
        }
        return [];
    }

    const onTreeCheck = (values) => {
        setPermission(values);
    }

    const toSubmit = async () => {
        if (!data.word) {
            message.warning('Please input role name');
            return;
        }
        setLoading(true);
        let actionsCreateReq = [];
        let allPermissions = [...permission, ...actionPermission];
        allPermissions.filter(menuKey => !allActions.map(item => item.name).includes(menuKey)).forEach(menuKey => {
            actionsCreateReq.push(createAdminAction({ name: menuKey, word: menuKey, menu: menuKey }))
        });
        if (actionsCreateReq.length > 0) {
            await Promise.all(actionsCreateReq);
        }
        let updatedActions = await getAllAction();
        let codes = allPermissions.map(item => {
            return updatedActions.find(action => action.name == item)?.code;
        })
        let params = {
            name: data.name,
            word: data.word,
            action: codes.join(',')
        }
        try {
            if (defaultData.id) {
                params.id = id;
                await updateRole(params);
                message.success('saved!')
            } else {
                await createRole(params);
                message.success('saved!')
            }
            navigateTo(MainLayoutUrl.systemconfig.roles.index.path);
        } catch (error) {
            message.error(String(error));
        } finally {
            setLoading(false);
        }
    }

    const onInputChange = (key, value) => {
        let updated = { ...data };
        updated[key] = value;
        setData(updated);
    }

    const disabledSubmit = () => {
        return !data.word || permission?.length <= 0
    }

    const onClose = () => {
        navigateTo(MainLayoutUrl.systemconfig.roles.index.path);
    }

    const onActionChange = (values) => {
        setActionPermission(values);
    }

    return (
        <div style={{ padding: '16px 24px' }}>
            <Descriptions
                title={defaultData.id ? 'Edit Admin Role' : 'Create Admin Role'}
                column={2}
                labelStyle={{ width: 180, textAlign: 'right' }}
                bordered colon>
                <Descriptions.Item label='Role Name' span={2}>
                    <Input allowClear value={data.word} placeholder="name" style={{ width: 240 }} onChange={evt => onInputChange('word', evt.target.value)} />
                </Descriptions.Item>
                <Descriptions.Item label='Desc' span={2}>
                    <Input allowClear value={data.name} placeholder="description" style={{ width: 240 }} onChange={evt => onInputChange('name', evt.target.value)} />
                </Descriptions.Item>
                <Descriptions.Item label='Menu Permission' span={2}>
                    <Tree checkedKeys={permission} treeData={allMenus} checkable showLine onCheck={onTreeCheck} />
                </Descriptions.Item>
                <Descriptions.Item label='Action Permission' span={2}>
                    <Checkbox.Group
                        value={actionPermission}
                        options={actionOptions}
                        onChange={onActionChange} />
                </Descriptions.Item>
            </Descriptions>
            <div className="btn-area">
                <Button loading={loading} disabled={disabledSubmit()} type="primary" style={{ width: 200, height: 44 }} onClick={toSubmit}>Submit</Button>
                <div style={{ width: 24 }} />
                <Button loading={loading} block style={{ width: 200, height: 44 }} onClick={onClose}>Cancel</Button>
            </div>
        </div>
    )
}

export default RoleDetailScreen;