import { useEffect, useState } from "react";
import { approvalRecords } from "../../api/community";
import { Button, Pagination, Table, Space, Tag, Image, Modal } from "antd";
import dayjs from "dayjs";
import { RichText } from "@chatui/core";
import '../../style/community.css';
import PostReview from "../../components/community/post_review";
import UserInfoView from "../../components/community/user_info_view";

const PostApproveRecordsModal = ({ postId, modalOpen, onClose }) => {
    const tabCols = [
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf', whiteSpace: 'nowrap' }}>{dayjs(text).format('MM-DD HH:mm')}</label>
            },
        },
        {
            title: 'Post Id',
            dataIndex: 'postId',
            key: 'postId',
            render: (_, record) => {
                const { id } = record.post;
                return <div style={{ fontSize: 12 }}>{id}</div>
            },
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (_, record) => {
                const { title } = record.post;
                let updatedTitle = title;
                if(record.title) {
                    updatedTitle = record.title;
                }
                if (!updatedTitle) return '-';
                return <div style={{ width: 100 }}><b>{updatedTitle}</b></div>
            },
        },
        {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
            render: (_, record) => {
                return <UserInfoView user={record.post?.user} showWallet={false} />
            },
        },
        {
            title: 'Content',
            dataIndex: 'content',
            key: 'content',
            render: (_, record) => {
                const { content, title } = record.post;
                let updatedContent = content;
                if(record.content) {
                    updatedContent = record.content;
                }

                let updatedTitle = title;
                if(record.title) {
                    updatedTitle = record.title;
                }
                return (
                    <div style={{ background: '#f1f1f1', padding: '8px 12px', borderRadius: 8, width: 160 }}>
                        <div className="community-content">
                            <RichText content={updatedContent ? updatedContent : updatedTitle} />
                        </div>
                    </div>
                )
            },
        },
        {
            title: 'NLP Result',
            dataIndex: 'nlpResult',
            key: 'nlpResult',
            render: (text, _) => {
                if (!text) return '';
                const nlps = JSON.parse(text);
                return (
                    <Space size={1} wrap>
                        {nlps.map((item, index) => {
                            return <Tag key={index}>{item.name}: <b>{item.confidence}</b></Tag>
                        })}
                    </Space>
                )
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            render: (_, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { reviewHandle(record) }}>Review</Button>
                    </Button.Group>
                )
            },
        }
    ];

    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [currPost, setCurrPost] = useState();

    useEffect(() => {
        fetchData(pageNum, pageSize);
    }, [postId])

    const fetchData = (pNum, pSize) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            postId,
        };
        approvalRecords(params).then(res => {
            if (res.page) {
                setTotal(res.page.total);
            }
            if (res.list) {
                setTabData(res.list);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size);
    }

    const reviewHandle = (record) => {
        let post = {...record.post};
        if(record.content) {
            post.content = record.content;
        }
        if(record.title) {
            post.title = record.title;
        }
        if(record.resources && record.resources.length > 0) {
            post.resources = record.resources;
        }

        setCurrPost(post);
        setOpen(true);
    }

    return (
        <>
            <Modal title='Post Approval Records' open={modalOpen} onCancel={onClose} width={'80vw'} footer={null}>
                <div className='cnt-item'>
                    <Table
                        scroll={{ x: true }}
                        loading={loading}
                        rowKey={(record) => record.id}
                        rowClassName={'table-row'}
                        size='small'
                        dataSource={tabData}
                        columns={tabCols}
                        pagination={false} />
                </div>
                <div className='footer-part cnt-item'>
                    <Pagination
                        total={total}
                        showTotal={(total) => `total ${total}`}
                        current={pageNum}
                        pageSize={pageSize}
                        showSizeChanger={true}
                        pageSizeOptions={[10, 20, 30, 50]}
                        onChange={(page, size) => pageChange(page, size)}
                    />
                </div>
            </Modal>
            <PostReview
                from="approve_post"
                open={open}
                post={currPost}
                onClose={() => setOpen(false)}
                onUpdate={() => fetchData(pageNum, pageSize)}
            />
        </>
    )
}

export default PostApproveRecordsModal;