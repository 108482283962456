import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export function getQueryString(name) {
    var vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
        function (m, key, value) {
            vars[key] = value;
        }
    );
    return vars[name];
}

export function conversionUtcDate(date, type, isShort, format) {
    if (format) {
        return dayjs.utc(date).local().format(format)
    }
    else if (type === 'UTC') {  // 传入UTC则将时间转换为UTC时间
        return dayjs(date).utc().format()
    }
    else if (isShort === true) {
        return dayjs.utc(date).local().format('YYYY-MM-DD HH:mm')
    }
    return dayjs.utc(date).local().format('YYYY-MM-DD HH:mm:ss')
}

export function getWeekdayDesc(day) {
    const week = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
    return week[day];
}

export function getMonthDesc(index) {
    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return month[index];
}

export function capitalizeFirstLetter(string) {
    if (typeof string !== 'string' || string.length === 0) {
        return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function agentIsAndriod(agent) {
    return /android/i.test(agent);
}

export function agentIsIos(agent) {
    return /iPad|iPhone|iPod/.test(agent);
}

export function htmlImgSrc(htmlstr) {
    let imgList = [];
    htmlstr.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
        imgList.push(capture);
        return '';
    });
    return imgList;
}

export function getParenthesesStr(text) {
    let result = '';
    let regex = /\((.+?)\)/g;
    let options = text.match(regex);
    if (options && options.length > 0) {
        let option = options[options.length - 1]
        if (option && option.length > 2) {
            result = option.substring(1, option.length - 1)
        }
    }
    if (result) return result;
    return text;
}

export function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function exportCsv(csv, title) {
    const t = dayjs.utc(Date.now()).local().format('YYYY-MM-DD')  // 当前日期
    const filename = `${t + title}.xlsx` // 拼接文件名

    const blob = new Blob([csv], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })  //创建一个新的 Blob 对象
    const url = window.URL.createObjectURL(blob)  //  把一个blob对象转化为一个Blob URL，创建下载链接
    const downloadLink = document.createElement('a')  // 生成一个a标签
    downloadLink.href = url
    downloadLink.download = filename  // // dowload属性指定下载后文件名
    document.body.appendChild(downloadLink) //将a标签添加到body中
    downloadLink.click() // 点击下载
    document.body.removeChild(downloadLink)  // 下载完成后移除元素
    window.URL.revokeObjectURL(url); // 释放掉blob对象
}


/**
 * 计算两个字符串的编辑距离（Levenshtein距离）
 * @param {string} str1 - 第一个字符串
 * @param {string} str2 - 第二个字符串
 * @returns {number} - 两个字符串之间的编辑距离
 */
function levenshteinDistance(str1, str2) {
    const len1 = str1.length;
    const len2 = str2.length;
    const dp = Array.from({ length: len1 + 1 }, () => Array(len2 + 1).fill(0));

    for (let i = 0; i <= len1; i++) {
        dp[i][0] = i;
    }
    for (let j = 0; j <= len2; j++) {
        dp[0][j] = j;
    }

    for (let i = 1; i <= len1; i++) {
        for (let j = 1; j <= len2; j++) {
            if (str1[i - 1] === str2[j - 1]) {
                dp[i][j] = dp[i - 1][j - 1];
            } else {
                dp[i][j] = Math.min(
                    dp[i - 1][j] + 1, // 删除
                    dp[i][j - 1] + 1, // 插入
                    dp[i - 1][j - 1] + 1 // 替换
                );
            }
        }
    }

    return dp[len1][len2];
}

/**
 * 计算两个字符串的相似度
 * @param {string} str1 - 第一个字符串
 * @param {string} str2 - 第二个字符串
 * @returns {number} - 两个字符串的相似度，范围在0到1之间
 */
export function calculateSimilarity(str1, str2) {
    const distance = levenshteinDistance(str1, str2);
    const maxLength = Math.max(str1.length, str2.length);
    return 1 - distance / maxLength;
}

export function iframeToVideoForH5(str) {
    let outstr = str.replace(/<iframe [^>]*src=['"]([^'"]+)[^>]*><\/iframe>/g, function (match, capture) {
        if (match.indexOf('ql-video') != -1 && capture.indexOf('.mp4') != -1) {
            return `<video class="ql-video" controls="controls" style="width: 100%; height: auto;" poster="${capture}?vframe/jpg/offset/0/w/480" type="video/mp4" src="${capture}"></video>`
        } else {
            return match
        }
    });
    return outstr
}