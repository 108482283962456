import request from '../utils/request';

export function fetchPosts(params) {
    return request.get('/post', params);
}

export function fetchPostCategory() {
    return request.get('/post/category');
}

export function fetchPostDetail(postId) {
    return request.get(`/post/get/${postId}`);
}

export function updatePost(id, params) {
    if(params.vote && (params.vote.options.length <= 0 || !params.vote.description)) {
        delete params['vote'];
    }
    return request.patch(`/post/update/${id}`, params);
}

export function deletePost(ids) {
    return request.dataDel(`/post/delete?ids=${ids}`);
}

export function createPost(params) {
    return request.post('/post/create', params);
}

export function createRecommendUser(params) {
    return request.post('/user/recommend/create', params);
}

export function updateRecommendUser(params) {
    return request.patch('/user/recommend/update', params);
}

export function deleteRecommendUser(ids) {
    return request.dataDel(`/user/recommend/delete?id=${ids}`);
}

export function fetchRecommendUsers(params) {
    return request.get('/user/recommend/find', params);
}

export function fetchUserSearch(params) {
    return request.get('/user/search', params);
}

export function fetchBountyTags(params) {
    return request.get('/reward/tag/list', params);
}

export function createBountyTag(params) {
    return request.post('/reward/tag/create', params);
}

export function fetchTagPosts(params) {
    return request.get('/reward/list', params);
}

export function fetchTagRewards(params) {
    return request.get('/reward/send/list', params);
}

export function createTagUserRewards(params) {
    return request.post('/reward/send', params);
}

export function updateTagUserReward(params) {
    return request.patch('/reward/send/edit', params);
}

export function updateUserVerification(params) {
    return request.post('/user/certified/create', params);
}

export function fetchVerificaitonApply(params) {
    return request.get('/user/certified/apply/list', params);
}

export function sendSystemNotification(params) {
    return request.post('/system/message/notify', params);
}

export function fetchReportList(params) {
    return request.get('/report', params);
}

export function updateReport(params) {
    return request.post('/report/review', params);
}

export function fetchAppealList(params) {
    return request.get('/appeal', params);
}

export function updateAppeal(params) {
    return request.post('/appeal/review', params);
}

export function fetchComments(params) {
    return request.get('/post/comment', params);
}

export function updateComments(params) {
    return request.post('/post/comment', params);
}

export function blockUser(params) {
    return request.post('/user/block', params);
}

export function fetchUserBalance(params) {
    return request.get('/user/balance', params);
}

export function fetchPostLikes(params) {
    return request.get('/post/user/likes', params);
}

export function fetchUserLoginInfo(params) {
    return request.get('/user/login/info', params);
}

export function fetchUserTags(params) {
    return request.get('/user/tag', params);
}

export function deleteUserTag(id) {
    return request.dataDel(`/user/tag?id=${id}`);
}

export function createUserTag(params) {
    return request.post('/user/tag/create', params);
}

export function updateUserTag(params) {
    return request.patch('/user/tag/edit', params);
}

export function userApplyTag(params) {
    return request.patch('/user/tag/user/edit', params);
}

export function fetchAllTags(params) {
    return request.get('/tag', params);
}

export function createTag(params) {
    return request.post('/tag/create', params);
}

export function updateTag(params) {
    return request.patch('/tag/edit', params);
}

export function deleteTag(id) {
    return request.dataDel(`/tag?id=${id}`);
}

export function fetchPostCreator(params) {
    return request.get('/user/platform', params);
}

export function fetchTopicTopPosts(params) {
    return request.get('/tag/post/top', params);
}

export function createTopicTopPost(params) {
    return request.post('/tag/post/top', params);
}

export function deleteTopicTopPost(id) {
    return request.dataDel(`/tag/post/top?id=${id}`);
}

export function fetchVirtualUsers(params) {
    return request.get('/virtual/user', params);
}

export function createVirtualUser(params) {
    return request.post('/virtual/user/create', params);
}

export function updateVirtualUser(params) {
    return request.patch('/virtual/user/edit', params);
}

export function createVirtualComment(params) {
    return request.post('/virtual/comment/create', params);
}

export function fetchCommentListStack(params) {
    return request.get('/comment/find', params);
}

export function fetchMoreChildComment(params) {
    return request.get('/comment/more', params);
}

export function fetchUserVote(params) {
    return request.get('/user/vote', params);
}

export function fetchCrawledList(params) {
    return request.get('/social/media/post/list', params)
}

export function deleteCrawledPost(ids) {
    return request.dataDel(`/social/media/post/delete?ids=${ids}`)
}

export function updateCrawledPost(params) {
    return request.patch('/social/media/post/update', params)
}

export function exportUsers(params) {
    return request.get('/download/user', params)
}

export function fetchProfilingQuiz(params) {
    return request.get('/signupQuizz/list', params);
}

export function exportUsersQuiz(params) {
    return request.get('/download/signupQuizz', params);
}

export function fetchPostApproveList(params) {
    return request.get('/approve', {...params, isPost: true})
}

export function fetchCommentApproveList(params) {
    return request.get('/approve', {...params, isComment: true})
}

export function approvePostOrComment(params) {
    return request.post('/approve', params);
}

export function approvalRecords(params) {
    return request.get('/approve/id', params);
}