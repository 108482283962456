import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { deletePost, fetchPosts, updatePost } from "../../api/community";
import { MainLayoutUrl } from "../../router";
import { Button, Pagination, Table, Flex, Select, Space, Input, Popconfirm, Tag, Modal, Tooltip, message, DatePicker, Popover, Typography } from "antd";
import { CheckOutlined, CloseCircleOutlined, DeleteOutlined, EditOutlined, EyeFilled, InfoCircleOutlined, LikeOutlined, MinusOutlined, PlusOutlined, TagsOutlined } from "@ant-design/icons";
import { useDebouncedCallback } from "use-debounce";
import dayjs from "dayjs";
import { RichText } from "@chatui/core";
import '../../style/community.css';
import PostReview from "../../components/community/post_review";
import PrizeImg from '../../assets/prize.png';
import UserInfoView from "../../components/community/user_info_view";
import PostLikesView from "../../components/community/post_like_users_view";
import PostAdminTagPopoverView from "./post_admin_tag_popover_view";

export const PostStatusOptions = [
    { key: 0, label: 'PUBLISHED', value: '1' },
    { key: 1, label: 'PENDING', value: '2' },
    { key: 2, label: 'RISK', value: '3' },
];

const PostPageView = ({ isPost, isAdmin }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const tooltipRef = useRef(null);
    const [popOpen, setPopOpen] = useState(false);
    const tabCols = [
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf', whiteSpace: 'nowrap' }}>{dayjs(text).format('MM-DD HH:mm')}</label>
            },
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, _) => {
                if (!text) return '-';
                return <div style={{ width: 100 }}><b>{text}</b></div>
            },
        },
        {
            title: 'Author',
            dataIndex: 'user',
            key: 'user',
            render: (text, _) => {
                return <UserInfoView user={text} showWallet={false} />
            },
        },
        {
            title: 'PostId',
            dataIndex: 'id',
            key: 'id',
            render: (text, _) => {
                return <div style={{ width: 80 }}><Typography.Text copyable style={{ fontSize: 11, color: '#afafaf' }}>{text}</Typography.Text></div>
            },
        },
        {
            title: 'Feature',
            dataIndex: 'feature',
            key: 'feature',
            render: (text, record) => {
                return (
                    <Space size={4} wrap>
                        {
                            (record.user.admin && record.reward && parseFloat(record.reward.count) > 0 && record.reward.id) && (
                                <Tag color="#f50" style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
                                    <img alt='post reward' src={PrizeImg} style={{ width: 16, height: 'auto', marginRight: 2 }} />
                                    <b>Prize</b>
                                </Tag>
                            )
                        }
                        {
                            record.vote && parseInt(record.vote.id ?? '0') > 0 && Array.from(record.vote.options ?? []).length > 0 && (
                                <Tag color="#55acee" style={{ fontStyle: 'italic' }}><b>Vote</b></Tag>
                            )
                        }
                    </Space>
                )
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (String(text) === '3') {
                    return (
                        <Tooltip ref={tooltipRef} title={record.riskReason} color='orange'>
                            <Tag color="red">RISK</Tag>
                        </Tooltip>
                    )
                } else if (String(text) === '2' || String(text) === '5' || String(text) === '6') {
                    return <Tag style={{ fontSize: 12 }} color="processing">PENDING</Tag>;
                } else if (String(text) === '1') {
                    return <Tag style={{ fontSize: 12 }}>PUBLISHED</Tag>;
                }
                return ''
            },
        },
        {
            title: 'Admin Tag',
            dataIndex: 'adminTags',
            key: 'adminTags',
            render: (text, _) => {
                if (!text || text.length <= 0) return '-';
                return (
                    <Space size={1} wrap style={{ width: 150 }}>
                        {
                            text.map(item => {
                                return <Typography.Text key={item.id} style={{ fontSize: 12 }} code>{`#${item.tag.name}`}</Typography.Text>
                            })
                        }
                    </Space>
                )
            },
        },
        {
            title: 'Tag',
            dataIndex: 'tags',
            key: 'tags',
            render: (text, _) => {
                if (!text) return '-';
                return (
                    <Space size={4} wrap style={{ width: 150 }}>
                        {
                            text.map(item => item.tag).filter(item => item.name || item.ticker).map((item, index) => {
                                if (item.market) {
                                    return <Typography.Text key={index} style={{ fontSize: 12 }} mark>{`$${item.ticker}`}</Typography.Text>
                                }
                                return <Typography.Text key={index} style={{ fontSize: 12 }} mark>{`#${item.name}`}</Typography.Text>
                            })
                        }
                    </Space>
                )
            },
        },
        {
            title: 'Content',
            dataIndex: 'content',
            key: 'content',
            render: (_, record) => {
                return (
                    <div style={{ background: '#f1f1f1', padding: '8px 12px', borderRadius: 8, width: 160 }}>
                        <div className="community-content">
                            <RichText content={record.content ? record.content : record.title} />
                        </div>
                    </div>
                )
            },
        },
        {
            title: 'Recommend Weight',
            dataIndex: 'recommend',
            key: 'recommend',
            render: (text, _) => {
                if (text > 0) return <b>{text}</b>;
                return '-';
            },
            sortDirections: ['descend'],
            sorter: true,
        },
        {
            title: 'Likes',
            dataIndex: 'agreeCount',
            key: 'agreeCount',
            render: (text, record) => {
                if (parseFloat(text) <= 0) return '-';
                return (
                    <Button type="link" onClick={() => toShowLikes(record)}><b>{text}</b></Button>
                );
            },
            sortDirections: ['descend'],
            sorter: true,
        },
        {
            title: 'Comments',
            dataIndex: 'commentCount',
            key: 'commentCount',
            render: (text, _) => {
                if (parseFloat(text) <= 0) return '-';
                return text;
            },
            sortDirections: ['descend'],
            sorter: true,
        },
        {
            title: 'UV',
            dataIndex: 'uv',
            key: 'uv',
            render: (text, _) => {
                if (text > 0) return <b>{text}</b>;
                return '-';
            },
        },
        {
            title: 'PV',
            dataIndex: 'viewCount',
            key: 'viewCount',
            render: (text, _) => {
                if (text > 0) return <b>{text}</b>;
                return '-';
            },
        },
        {
            title: 'Shares',
            dataIndex: 'shareCount',
            key: 'shareCount',
            render: (text, _) => {
                if (text > 0) return <b>{text}</b>;
                return '-';
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            render: (text, record, index) => {
                return (
                    <Space size='small'>
                        <Tooltip title='Review Post' key={`review_${index}`}>
                            <Button shape="circle" onClick={() => { reviewHandle(record) }} icon={<EyeFilled />} />
                        </Tooltip>
                        {
                            isAdmin ?
                                <>
                                    <Tooltip title='Edit' key={`edit_${index}`}>
                                        <Button shape="circle" onClick={() => { editHandle(record) }} icon={<EditOutlined />} />
                                    </Tooltip>
                                    <Tooltip title='Recommend' key={`Recommend_${index}`}>
                                        <Button shape="circle" onClick={() => { recommendHandle(record) }} icon={<LikeOutlined />} />
                                    </Tooltip>
                                    <Popconfirm
                                        title="Delete Action"
                                        description="Are you sure to delete this post?"
                                        onConfirm={() => { deleteHandle(record) }}
                                        okText="Yes"
                                        cancelText="No" >
                                        <Button danger shape="circle" icon={<DeleteOutlined />} />
                                    </Popconfirm>
                                </>
                                :
                                ((String(record.status) === '1') ?
                                    <>
                                        <Popover
                                            placement="topRight"
                                            title='Set admin tags'
                                            open={popOpen && currPost?.id === record.id}
                                            content={
                                                <PostAdminTagPopoverView
                                                    closed={popOpen}
                                                    defaultTags={record.adminTags ? record.adminTags.map(item => item.tag.name) : []}
                                                    confirmCallback={tagsConfirmHandle} />}
                                            trigger={'click'}
                                            onOpenChange={onOpenChange}>
                                            <Button shape="circle"
                                                onClick={() => {
                                                    setCurrPost(record);
                                                    setPopOpen(true);
                                                }}
                                                icon={<TagsOutlined />} />
                                        </Popover>
                                        <Tooltip title='Recommend' key={`Recommend_${index}`}>
                                            <Button shape="circle" onClick={() => { recommendHandle(record) }} icon={<LikeOutlined />} />
                                        </Tooltip>
                                        <Tooltip title='Reject' key={`Reject_${index}`}>
                                            <Button danger type="primary" shape="circle" onClick={() => { rejectHandle(record) }} icon={<MinusOutlined />} />
                                        </Tooltip>
                                    </>
                                    :
                                    (
                                        (String(record.status) === '3')
                                            ?
                                            <Tooltip title='Revoke' key={`Approve_${index}`}>
                                                <Button type="primary" shape="circle" onClick={() => { revokeHandle(record) }} icon={<CheckOutlined />} />
                                            </Tooltip>
                                            :
                                            null
                                    )
                                )
                        }
                    </Space>
                )
            },
        }
    ];

    const [searchParams, setSearchParams] = useState({
        keyword: '',
        categoryId: isPost ? 1 : 2,
        status: '',
        admin: isAdmin ? true : false,
        gteCreatedAt: '',
        ltCreatedAt: '',
        order: '',
    });
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [currPost, setCurrPost] = useState();
    const [likesDrawerShow, setLikesDrawerShow] = useState(false);

    useEffect(() => {
        fetchData(pageNum, pageSize, searchParams);
    }, [location])

    const tagsConfirmHandle = (tags) => {
        if (!currPost) return;
        setLoading(true);
        let params = { ...currPost, adminTag: tags };
        delete params['adminTags'];
        delete params['tags'];
        delete params['tag'];
        delete params['events'];
        delete params['topic'];
        updatePost(currPost.id, params).then(res => {
            if (res) {
                setPopOpen(false);
                setCurrPost(null);
                setLoading(false);
                fetchData(pageNum, pageSize, searchParams);
            }
        })
    }

    const onOpenChange = (value) => {
        setPopOpen(value);
    }

    const fetchData = (pNum, pSize, search) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            ...search,
        };
        fetchPosts(params).then(res => {
            if (res.page) {
                setTotal(res.page.total);
            }
            if (res.list) {
                setTabData(res.list);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size, searchParams);
    }

    const createNewPost = () => {
        navigate(MainLayoutUrl.community.adminpost.item.path.replace(':id', 0))
    }

    const editHandle = (item) => {
        navigate(MainLayoutUrl.community.adminpost.item.path.replace(':id', item.id), { state: { detail: JSON.stringify(item) } })
    }

    const deleteHandle = (item) => {
        setLoading(true);
        deletePost(item.id).then(res => {
            fetchData(pageNum, pageSize, searchParams);
        }).catch(err => message.error(String(err))).finally(() => {
            setLoading(false);
        });
    }

    const toShowLikes = (post) => {
        setCurrPost(post);
        setLikesDrawerShow(true);
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            if (tag === 'dates') {
                if (value.length == 2) {
                    newParams.gteCreatedAt = `${value[0]}T00:00:00Z`;
                    newParams.ltCreatedAt = `${value[1]}T23:59:59Z`;
                } else {
                    newParams.gteCreatedAt = '';
                    newParams.ltCreatedAt = '';
                }
            } else {
                newParams[tag] = value;
            }
            setSearchParams(newParams);
            setPageNum(1);
            fetchData(1, pageSize, newParams);
        },
        300
    )

    const rejectHandle = (record) => {
        let reason = '';
        let modal = Modal.confirm({
            closable: false,
            title: 'Are you sure to reject this post?',
            content: (
                <div>
                    <Input style={{ color: 'red' }} prefix={<span style={{ color: '#333333' }}>Reason: </span>} placeholder="reject reason" onChange={evt => {
                        reason = evt.target.value;
                        modal.update({
                            okButtonProps: {
                                disabled: !reason.trim(),
                            }
                        })
                    }} allowClear />
                </div>
            ),
            okText: 'Confirm',
            okButtonProps: {
                disabled: !reason.trim(),
            },
            cancelText: 'Cancel',
            onOk: async () => {
                let params = { ...record, status: 3, riskReason: reason };
                delete params['adminTags'];
                delete params['tags'];
                delete params['tag'];
                delete params['events'];
                delete params['topic'];
                try {
                    await updatePost(record.id, params);
                    fetchData(pageNum, pageSize, searchParams);
                } catch (error) {
                    message.error(String(error));
                }
            }
        })
    }

    const revokeHandle = (record) => {
        setLoading(true);
        let params = { ...record, status: 1, riskReason: '' };
        delete params['adminTags'];
        delete params['tags'];
        delete params['tag'];
        delete params['events'];
        delete params['topic'];
        updatePost(record.id, params).then(res => {
            fetchData(pageNum, pageSize, searchParams);
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        });
    }

    const recommendHandle = (record) => {
        let weight = String(record.recommend);
        let modal = Modal.confirm({
            closable: false,
            title: 'Recommend the post?',
            content: (
                <div>
                    <Input defaultValue={weight} type="number" prefix={'Recommend Weight: '} placeholder="weight" onChange={evt => {
                        weight = evt.target.value;
                        modal.update({
                            okButtonProps: {
                                disabled: !weight.trim(),
                            }
                        })
                    }} allowClear />
                </div>
            ),
            okText: 'Confirm',
            okButtonProps: {
                disabled: !weight.trim(),
            },
            cancelText: 'Cancel',
            onOk: async () => {
                let params = { ...record, recommend: weight };
                delete params['adminTags'];
                delete params['tags'];
                delete params['tag'];
                delete params['events'];
                delete params['topic'];
                await updatePost(record.id, params);
                fetchData(pageNum, pageSize, searchParams);
            }
        })
    }

    const reviewHandle = (record) => {
        setCurrPost(record);
        setOpen(true);
    }

    const onTabChange = (pagination, filters, sorter, extra) => {
        let orderValue = '';
        if (sorter.order) {
            orderValue = `${sorter.field}${sorter.order === 'descend' ? ' DESC' : ''}`
        }
        onSearchChange('order', orderValue);
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify="space-between">
                    {
                        isAdmin ?
                            <Button type='primary' icon={<PlusOutlined />} onClick={createNewPost}>Create New Post</Button>
                            :
                            <div />
                    }

                    <Space wrap style={{ justifyContent: 'flex-end' }}>
                        <DatePicker.RangePicker onChange={values => onSearchChange('dates', (values ?? []).map(date => dayjs(date).format('YYYY-MM-DD')))} />
                        <Select
                            style={{ width: 160 }}
                            options={PostStatusOptions}
                            placeholder='Post status'
                            onChange={value => onSearchChange('status', value)}
                            allowClear
                        />
                        <Input
                            placeholder="keywords search"
                            allowClear
                            onChange={evt => onSearchChange('keyword', evt.target.value)}
                        />
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table
                    scroll={{ x: true }}
                    loading={loading}
                    rowKey={(record) => record.id}
                    rowClassName={'table-row'}
                    size='small'
                    dataSource={tabData}
                    columns={isAdmin ? tabCols.filter(item => item.dataIndex !== 'adminTags') : tabCols}
                    pagination={false}
                    onChange={onTabChange} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>

            <PostReview
                from={isPost ? 'community_post' : 'community_article'}
                open={open}
                post={currPost}
                onClose={() => setOpen(false)}
                onUpdate={() => fetchData(pageNum, pageSize, searchParams)}
            />

            <PostLikesView
                open={likesDrawerShow}
                post={currPost}
                onClose={() => setLikesDrawerShow(false)} />
        </div>
    )
}

export default PostPageView;