import { Input, Button, message, Select, Form, Row, Col, Drawer, Space, Avatar, Spin, Typography, Radio } from 'antd';
import { createVirtualComment, fetchPosts, fetchVirtualUsers } from '../../api/community';
import { useEffect, useMemo, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';
import { conversionUtcDate } from '../../utils/comm';
import { CloudStorageImageUploader } from '../upload';
import { customUploaderBtn } from '../comm/custom_uploader_btn';

const VirtualCommentModalView = ({ open, defaultComment, onClose, onUpdate }) => {
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [form] = Form.useForm();
    const [posts, setPosts] = useState([]);
    const [showUserSelect, setShowUserSelect] = useState(false);
    const [users, setUsers] = useState([]);
    const [resources, setResources] = useState([])

    useEffect(() => {
        form.setFieldValue('search', null);
        if (defaultComment) {
            form.setFieldValue('postId', defaultComment.post.id)
            form.setFieldValue('title', defaultComment.post.title)
            form.setFieldValue('content', defaultComment.content)
        } else {
            resetFields();
        }
        handlePostSearch('');
        handleUserSearch('');
    }, [defaultComment]);

    const resetFields = () => {
        form.setFieldValue('search', null);
        form.setFieldValue('postId', '')
        form.setFieldValue('userId', null)
        form.setFieldValue('title', '')
        form.setFieldValue('content', '')
        form.setFieldValue('category', 1)
        form.setFieldValue('userType', showUserSelect ? 2 : 1)
        setResources([]);
    }

    const onSubmit = () => {
        let params = {
            id: defaultComment?.id,
            postId: form.getFieldValue('postId'),
            userId: form.getFieldValue('userId'),
            content: form.getFieldValue('content'),
            resources: resources.map(item => ({url: item}))
        };
        if (!params.postId) {
            message.warning('Please choose a post');
            return;
        }
        setLoading(true);
        if (params.id) {

        } else {
            createVirtualComment(params).then(res => {
                resetFields();

                onClose();
                onUpdate();
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const onPostSelectChange = (value) => {
        form.setFieldValue('postId', value);
        let post = posts.find(item => item.value === value);
        if (post) {
            form.setFieldValue('title', post.label);
        }
    }

    const onUserSelectChange = (value) => {
        form.setFieldValue('userId', value);
    }

    const handlePostSearch = useDebouncedCallback(
        (keyword) => {
            let params = {
                'page.num': 1,
                'page.size': 10,
                'keyword': keyword,
                'status': 1,
                'order': 'agreeCount DESC',
                'categoryId': form.getFieldValue('category') ?? 1,
            }
            setSearchLoading(true);
            fetchPosts(params).then(res => {
                if (res.list) {
                    let tmp = res.list.map(item => {
                        let label = item.title;
                        if (!label) {
                            let div = document.getElementById('contentHolder');
                            div.innerHTML = '';
                            div.innerHTML = item.content;
                            label = div.innerText;
                        }
                        return {
                            avatar: (item.user.picture ? item.user.picture : item.user.avatar),
                            like: item.agreeCount,
                            comment: item.commentCount,
                            createdAt: conversionUtcDate(item.createdAt, null, true),
                            label: label,
                            value: item.id,
                        }
                    });
                    setPosts(tmp);
                }
            }).finally(() => setSearchLoading(false))
        },
        300
    )

    const handleUserSearch = useDebouncedCallback(
        (keyword) => {
            let params = {
                'page.num': 1,
                'page.size': 10,
                'keyword': keyword,
            }
            setSearchLoading(true);
            fetchVirtualUsers(params).then(res => {
                if (res.list) {
                    setUsers(res.list.map(item => ({...item, label: item.name, value: item.userId})));
                }
            }).finally(() => setSearchLoading(false))
        },
        300
    )

    const drawTitle = () => {
        return defaultComment ? 'Edit Virtual Comment' : 'Create Virtual Comment'
    }

    const onCategoryChange = (value) => {
        handlePostSearch();
    }

    const onUserTypeChange = (value) => {
        if (value === 1) {
            setShowUserSelect(false);
            form.setFieldValue('userId', null);
        } else {
            setShowUserSelect(true);
        }
    }

    const onTagCoverImageUpdate = (files) => {
        if (files[0]) {
            setResources([files[0].remoteUrl ? files[0].remoteUrl : files[0].url])
        } else {
            setResources([])
        }
    }

    const virtualCommentUploadProps = {
        defaultFileList: useMemo(() => {
            return resources && resources[0] ? [{
                name: (decodeURIComponent(resources[0]).split('/').pop() ?? '').split('?')[0],
                url: resources[0],
                percent: 100,
            }] : [];
        }, [resources]),
        name: 'virtual-comment-uploader',
        bucketPath: '/virtual-comment',
        listType: 'picture-card',
        isMultiple: false,
        needItemRender: false,
        useCardPreview: true,
        showUploadList: true,
        buttonNode: customUploaderBtn('Upload Image'),
        onUpdate: onTagCoverImageUpdate
    }

    return (
        <div>
            <Drawer
                forceRender={true}
                title={drawTitle()}
                width={480}
                maskClosable
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    requiredMark={false} >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='category' label='Post Category'>
                                <Radio.Group onChange={onCategoryChange}>
                                    <Radio.Button value={1}>Post</Radio.Button>
                                    <Radio.Button value={2}>Article</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='search' label='Search'>
                                <Select
                                    style={{ width: '100%' }}
                                    size='large'
                                    placeholder='you can input keywords to search...'
                                    defaultActiveFirstOption={false}
                                    suffixIcon={null}
                                    filterOption={false}
                                    onSearch={handlePostSearch}
                                    notFoundContent={searchLoading ? <Spin /> : null}
                                    options={posts}
                                    optionRender={(option) => {
                                        return (
                                            <Space>
                                                {
                                                    option.data.avatar ?
                                                        <Avatar size={32} src={option.data.avatar} />
                                                        :
                                                        <Avatar size={32} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                                }
                                                <div style={{ flex: 1 }}>
                                                    <b style={{ wordBreak: 'break-word' }}>{option.data.label ? option.data.label : 'anonymous'}</b>
                                                    <div>
                                                        <Typography.Text style={{ color: '#999', fontSize: 12 }}>
                                                            {option.data.createdAt}&nbsp;&nbsp;
                                                            Like: {option.data.like}&nbsp;&nbsp;Comment: {option.data.comment}
                                                        </Typography.Text>
                                                    </div>
                                                </div>
                                            </Space>
                                        )
                                    }}
                                    onChange={onPostSelectChange}
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ height: 36, width: 200 }} />

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='postId' label='Post ID'>
                                <Input placeholder="please input post/article id" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='title' label='Post Title'>
                                <Input.TextArea autoSize disabled />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='userType' label='User Type'>
                                <Radio.Group onChange={(evt) => onUserTypeChange(evt.target.value)}>
                                    <Radio.Button value={1}>Random</Radio.Button>
                                    <Radio.Button value={2}>Specify</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    {
                        showUserSelect &&
                        (
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item name='userId' label='Virtual User'>
                                        <Select
                                            style={{ width: '100%' }}
                                            size='large'
                                            placeholder='you can input keywords to search...'
                                            defaultActiveFirstOption={false}
                                            suffixIcon={null}
                                            filterOption={false}
                                            onSearch={handleUserSearch}
                                            notFoundContent={searchLoading ? <Spin /> : null}
                                            options={users}
                                            optionRender={(option) => {
                                                return (
                                                    <Space>
                                                        {
                                                            option.data.picture ?
                                                                <Avatar size={32} src={option.data.picture} />
                                                                :
                                                                <Avatar size={32} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                                        }
                                                        <div style={{ flex: 1 }}>
                                                            <b>{option.data.name}</b>
                                                            <div>
                                                                <Typography.Text style={{ color: '#999', fontSize: 12 }}>
                                                                    {option.data.username}
                                                                </Typography.Text>
                                                            </div>
                                                        </div>
                                                    </Space>
                                                )
                                            }}
                                            onChange={onUserSelectChange}
                                            allowClear
                                            showSearch
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )
                    }
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='content' label='Comment Content'>
                                <Input.TextArea rows={5} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='resource' label='Comment Image'>
                                <CloudStorageImageUploader {...virtualCommentUploadProps} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>

            <div style={{ display: 'none' }} id='contentHolder' />
        </div>
    )
}

export default VirtualCommentModalView;